<template>
  <div class="operationEditDialog">
    <!-- <el-dialog title="手动提现" :visible.sync="dialogVisible" width="600px" :close-on-click-modal="false" @close="close"> -->
      <el-form :model="operationForm" ref="operationForm" :rules="rules" label-width="auto">
        <el-input type="hidden" v-model="operationForm.id"></el-input>
        <el-row :gutter="25">
          <el-col :span="16">
            <el-form-item label="ID" prop="uid" >
              <el-input v-model="operationForm.uid" placeholder="请输入ID" style="width:300px">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="提现金额" prop="price">
              <el-input v-model="operationForm.price" placeholder="请输入提现金额" style="width:300px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="姓名" prop="name">
              <el-input v-model="operationForm.name" placeholder="请输入姓名" style="width:300px"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="是否是广告收益" prop="is_ad">
              <!-- <el-input v-model="operationForm.name" placeholder="请输入名称"></el-input> -->
              <el-select v-model="operationForm.is_ad" clearable style="width:300px">
                <el-option value="2" label="是"></el-option>
                <el-option value="1" label="否"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    <!-- </el-dialog> -->
  </div>
</template>

<script>
import { wyjiesuan } from '@/request/api'
export default {
  name: "operationEditDialog",
  components: {},
  data() {
    return {
      dialogVisible: false,
      operationForm: {
        uid:'',
        price:'',
        name:"",
        is_ad:""
      },
      rules: {
         uid: [{ required: true, message: 'ID不能为空', trigger: 'blur' }],
         price: [{ required: true, message: '提现金额不能为空', trigger: 'blur' }],
         name: [{ required: true, message: '名称不能为空', trigger: 'blur' }],
         is_ad: [{ required: true, message: '请选择是否为广告收益', trigger: 'blur' }],

      }
    }
  },
  created: function () { },
  mounted: function () { },
  methods: {
    show() {
    //   this.dialogVisible = true;
    },
    close() {
    //   this.dialogVisible = false;
      this.operationForm.uid=''
      this.operationForm.price=''
      this.operationForm.name=''
      this.operationForm.is_ad=''

      // this.$nextTick(() => {
        this.$refs.operationForm.clearValidate(); //关闭清空校验规则
      // })
    },
    submitForm() {
      this.$refs.operationForm.validate(async (valid) => {
        if (valid) {
          let res = await wyjiesuan(this.operationForm);
          console.log(res,"5555555555");
          if (res.data.code == 200) this.$message.success("提现成功");
          this.close();
        //   this.$parent.getUserList();
        } else {
            this.$message.error(res.data.msg)
            this.close();
            return false;
        }
      });
    }
  }
}
</script>

<style>
.dialog-footer{
    margin-left:180px;
}
</style>
